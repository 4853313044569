import { useState } from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  const [openNavBar, setOpenNavBar] = useState(false);
  return (
    <nav className="navbar">
      <div className="container">
        <h1 className="name">bluapedevs</h1>
        <ul className="nav desktop">
          <li>
            <Link to="/home">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/our-services">Our services</Link>
          </li>
          <li className="btn border-btn">
            <Link to={"/get-quote"}>Get a quote</Link>
          </li>
          <li className="btn main">
            <Link to="/get-started">Get Started</Link>
          </li>
        </ul>
        <button className="burger-nav" onClick={() => setOpenNavBar(true)}>
          <span />
          <span />
          <span />
        </button>
        {openNavBar && (
          <div className="side-bar">
            <div className="sidenav-container">
              <button class="close-nav " onClick={() => setOpenNavBar(false)}>
                <span />
                <span />
              </button>
              <h1 className="name">bluapedevs</h1>
              <ul className="nav">
                <li>
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="">Our services</Link>
                </li>
                <li>
                  <Link to="/contact-me">Contact me</Link>
                </li>
                <li className="btn">
                  <Link className="btn main" to={"/get-started"}>
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link className="btn border" to={"/get-quote"}>
                    Get a quote
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Nav;
