import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <ul>
          <li className="logo">
            <Link to="/">bluapedevs</Link>
          </li>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/">about</Link>
          </li>
          <li>
            <Link to="/">Our services</Link>
          </li>
          <li>
            <Link to="/">Contact us</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/">Facebook</Link>
          </li>
          <li>
            <Link to="/">Twitter</Link>
          </li>
          <li>
            <Link to="/">Instagram</Link>
          </li>
          <li>
            <Link to="/">LinkedIn</Link>
          </li>
        </ul>
        <div className="copyright">
          <span>7 Tamworth bay,</span>
          <span>Winnipeg, MB,CA</span>
          <span>&copy;www.bluapedevs.com</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
