import { Link } from "react-router-dom";
import Nav from "../components/nav";
import appDev from "../assets/app-development.png";
import coding from "../assets/coding.png";
import design from "../assets/design.png";
import mobileDev from "../assets/mobile-development.png";
import Footer from "../components/footer";
import heroImage from "../assets/hero_image.png";

const Home = () => {
  return (
    <>
      <Nav />
      <header>
        <div className="container">
          <div className="introduction">
            <div className="title-container">
              <h1 className="bg-title">We design,</h1>
              <h1 className="bg-title">We build,</h1>
              <h1 className="bg-title">We manage,</h1>
              <h1 className="bg-title">you do you.</h1>
            </div>
            <p>
              We are your one-stop solution for all things digital. Whether
              you're in need of cutting-edge web applications, user-friendly
              mobile apps, or stunning UI/UX design, we've got you covered. Our
              motto is simple: "We build, We manage, we design, you do you." We
              take pride in crafting bespoke web and mobile solutions tailored
              to your unique requirements,{" "}
            </p>
            <div className="cta-container">
              <Link to="#" className="main">
                Build for me
              </Link>
              <Link to="#" className="sub">
                Get a quote
              </Link>
            </div>
          </div>
          <div className="hero-image-container">
            <img src={heroImage} alt="Hero, we build software that matters" />
          </div>
        </div>
      </header>
      <section className="sc offers">
        <div className="container">
          <h1 className="md-title">What we have to offer</h1>
          <div className="offers">
            <div className="offer">
              <img src={design} alt="Mobile app development" />
              <h3 className="offer-title">UI/UX Design</h3>
              <p>
                A well-crafted UI/UX design not only attracts users but also
                keeps them coming back. By focusing on usability, aesthetics,
                and a seamless flow, you can improve user retention rates. Users
                are more likely to stick around, use your app or website more
                frequently, and even recommend it to others, ultimately boosting
                customer loyalty and brand advocacy
              </p>
            </div>
            <div className="offer">
              <img src={mobileDev} alt="UI/UX design" />
              <h3 className="offer-title">Mobile App Development</h3>
              <p>
                App development enables you to build solutions that specifically
                cater to your business's unique needs and challenges.
                Off-the-shelf apps may not address your specific requirements,
                but a tailored app ensures that it aligns perfectly with your
                processes, helping you optimize efficiency and productivity.
              </p>
            </div>
            <div className="offer">
              <img src={coding} alt="Web development" />
              <h3 className="offer-title">Web Development</h3>
              <p>
                A responsive web design is favoured by search engines, leading
                to better search engine optimization (SEO) performance. This
                means your website is more likely to rank higher in search
                results, increasing its visibility to potential customers.
                Higher visibility can result in more organic traffic, which can
                translate into increased leads and conversions for your
                business.
              </p>
            </div>
            <div className="offer">
              <img src={appDev} alt="Web development" />
              <h3 className="offer-title">Management</h3>
              <p>
                Focus on your business while we handle the technical aspects. We
                keep your app up-to-date, secure, and optimized for performance,
                saving you time and resources.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="book-call">
        <div className="container">
          <h1 className="lg-title">We would love to hear from you</h1>
          <p>
            We value your input and appreciate your interest in our services.
            Whether you have a project in mind, need assistance, or simply want
            to provide feedback, we're all ears. Our dedicated team is committed
            to providing you with the best possible solutions. Reach out to us,
            and let's start a conversation today!
          </p>
          <Link to="/som">Book a call</Link>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
